// TemperatureLabels.js
export const TemperatureLabels = (temperature) => {
  if (!temperature) return "No Record Added";

  if (temperature >= 100 && temperature <= 110) {
    return <p className="text-red-500">{temperature}°F</p>;
  } else if (temperature >= 90 && temperature <= 100) {
    return <p className="text-green-500">{temperature}°F</p>;
  } else if (temperature >= 30 && temperature < 40) {
    return <p className="text-green-500">{temperature}°C</p>;
  } else if (temperature >= 40 && temperature <= 45) {
    return <p className="text-red-500">{temperature}°C</p>;
  } else {
    return <p>{temperature}°</p>;
  }
};

export const OxygenSaturationLabels = (oxygen) => {
  if (!oxygen) return "No Record Added";

  if (oxygen >= 95) {
    return <p className="text-green-500">{oxygen}%</p>;
  } else if (oxygen > 92 && oxygen < 95) {
    return <p className="text-yellow-400">{oxygen}%</p>;
  } else {
    return <p className="text-red-500">{oxygen}%</p>;
  }
};
